/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import { FormLine } from "../forms/FormLine";
import { FormObject } from "../forms/FormObject";

const anyWin = window as any;

anyWin.isMobileApp = true;

anyWin.getFormElement = function (column: any, form?: any) {
	if (form && form.getFormElement) return form.getFormElement(column);
	return anyWin.currentForm.getFormElement(column);
};

anyWin.setFormElement = function (column: any, value: any) {
	var fl = anyWin.getFormLineByColumnName(column, null);
	if (fl) fl.setValue(value);
};

export function setFormElement(column: any, value: any) {
	anyWin.setFormElement(column, value);
}

anyWin.setPageVariables = function (formObject: any) {
	anyWin.formXML = formObject;
	anyWin.workerID = formObject.workerID;
	anyWin.currentAgency = formObject.agencyId;
	anyWin.agencyID = formObject.agencyId;
	anyWin.eventID = formObject.eventID;
	anyWin.eventCategory = formObject.eventCategory;
	anyWin.programPS = formObject.programPS;
	anyWin.programEnrollment = formObject.programEnrollment;
	anyWin.sitePS = formObject.sitePS;
	anyWin.parentValue = formObject.parentValue;
	anyWin.serviceTrack = formObject.serviceTrack;
	anyWin.schedLoc = null;
	anyWin.keyValue = formObject.keyValue;
	if (!formObject.isSubForm) {
		anyWin.parentForm = formObject;
	}
};

anyWin.parseIntFromTd = function (val: any) {
	if (isNullOrUndefined(val)) return null;
	if (typeof val == "number") return val;

	val = val.toString();

	if (val.includes(":")) {
		const split = val.split(":");
		return parseInt(split[0] || 0) * 60 + parseInt(split[1]);
	}

	return parseInt(val);
};

anyWin.getFormLineById = function (formLinesId: any, form: any) {
	if (!formLinesId) return null;

	if (!form) form = anyWin.currentForm;

	return form.FormLines.find((x: any) => x.formLinesId.toLowerCase() === formLinesId.toLowerCase());
};

anyWin.getCurrentAgency = function () {
	return anyWin.currentAgency;
};

anyWin.getModuleProperty = function (col: any) {
	switch (col) {
		case "currentBelongs2Event":
			return anyWin.serviceTrack;
		default:
			break;
	}
};

anyWin.getParentFormElement = function (col: any) {
	return anyWin.getElementFromXML(anyWin.parentForm, col);
};

anyWin.getElementFromXML = function (formXML: any, columnName: any) {
	return anyWin.getFormElement(columnName, formXML);
};

anyWin.GetDefaultStaff = function () {
	return anyWin.workerID;
};

export function parseTimeDurationFromUser(val: string) {
	let newVal = val;
	let amt = 0;
	if (newVal.includes(":")) {
		const split = newVal.split(":");
		let hours = parseInt(split[0]);
		if (isNaN(hours)) {
			hours = 0;
		}

		let minutes = parseInt(split[1]);
		if (isNaN(minutes)) {
			minutes = 0;
		}

		amt = hours * 60 + minutes;
	} else if (newVal.includes(".")) {
		amt = parseFloat(newVal) * 60;
	} else {
		amt = parseInt(newVal);
	}
	return FormatDataByTypeCode(amt, "TD");
}
export function GetDefaultStaffTS() {
	return anyWin.GetDefaultStaff();
}

anyWin.GetIsTelehealth = function () {
	return false;
};

export function parseTimeFromUser(val: any) {
	const localVal = val.toString().toLowerCase();
	if (isNullOrUndefined(val)) return "";
	let newTime = "";

	const justTime = localVal.replace("a", "").replace("p", "").replace("m", "").trim();
	let split = [] as Array<string>;
	const splitters = [":", ","];
	splitters.forEach((x) => {
		if (justTime.indexOf(x) > -1) split = justTime.split(x);
	});
	let ampm = false;
	let hour = 0;
	let minute = 0;
	if (split.length > 0) {
		const h = split[0].trim();
		const m = split[1].trim();

		hour = parseInt(h);
		minute = parseInt(m);
	} else if (justTime.length <= 2) {
		hour = parseInt(justTime);
	} else if (justTime.length === 3) {
		const h = justTime.substring(0, 1);
		const m = justTime.substring(1, 3);
		hour = parseInt(h);
		minute = parseInt(m);
	} else if (justTime.length === 4) {
		const h = justTime.substring(0, 2);
		const m = justTime.substring(2, 4);
		hour = parseInt(h);
		minute = parseInt(m);
	} else return "";

	if (hour < 0 || hour > 23) return "";
	if (minute < 0 || minute > 59) return "";

	if (hour > 11) ampm = true;

	hour = hour > 12 ? hour % 12 : hour;

	newTime = hour.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0");

	if (localVal.includes("a") || localVal.includes("p")) {
		ampm = localVal.includes("p");
	}

	newTime += ampm ? " PM" : " AM";
	return newTime;
}

anyWin.getFormLineByColumnName = function (col: any, form?: any) {
	return (form || anyWin.currentForm).FormLines.find((x: any) => x.columnName.toLowerCase() === col?.toLowerCase());
};

anyWin.getFormLineByCaption = function (caption: any) {
	return anyWin.currentForm.FormLines.find((x: any) => x.caption.toLowerCase() === caption?.toLowerCase());
};

anyWin.parseBoolean = function (val: any) {
	return "YES|1|ON|TRUE".includes(val ? val.toString().toUpperCase() : "off");
};
anyWin.getQueryParam = function (param: string): string {
	const params = new URLSearchParams(anyWin.location.search);
	const res = params.get(param);
	if (res) return res;

	return null as any;
};

anyWin.formatTime = function (val: any) {
	if (val == null) return "";

	var dt = new Date(val.toString());
	if (dt.toString().includes("Invalid")) {
		dt = new Date("1/1/1900 " + val.toString());
	}

	if (dt != null && !dt.toString().includes("Invalid")) {
		var hour = dt.getHours();
		var am = true;
		if (hour === 0) hour = 12;
		else if (hour >= 12) {
			am = false;
			hour = hour > 12 ? hour % 12 : 12;
		}

		var minutes = dt.getMinutes();
		return hour.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + " " + (am ? "AM" : "PM");
	}

	return "";
};

anyWin.formatPhoneString = function (numberString: string) {
	let formatted = "";
	if (isNullOrUndefined(numberString)) return formatted;

	numberString = anyWin.GetNumbers(numberString);
	if (numberString.length === 0) return formatted;

	if (numberString.length > 10 && numberString[0] === "1") {
		numberString = numberString.substring(1);
	}

	if (numberString.length === 7) {
		formatted = numberString.substring(0, 3) + "-" + numberString.substring(3);
	} else {
		formatted = `(${numberString.substring(0, 3)}) ${numberString.substring(3, 6)}-${numberString.substring(6, 10)}`;
		if (numberString.length > 10) formatted += ` x ${numberString.substring(10, 15)}`;
	}

	return formatted;
};

anyWin.GetNumbers = function (cString: any) {
	if (!cString) {
		return cString;
	}

	var cResult = "";

	if (cString.length > 0) {
		var cNumbers = "01234567890";

		for (var i = 0; i < cString.length; i++) {
			var cTemp = cString.substring(i, i + 1);
			if (cNumbers.indexOf(cTemp) !== -1) {
				cResult = cResult + cTemp;
			}
		}
	}

	return cResult;
};

anyWin.FormatDataByTypeCode = function (val: any, typeCode: string) {
	let f = "";
	let local: any = "";
	switch (typeCode) {
		case "TM":
			f = anyWin.formatTime(val);
			break;
		case "T":
			f = anyWin.formatPhoneString(val);
			break;
		case "SSN":
			if (val && val.length > 0) {
				f = val.substring(0, 3) + "-" + val.substring(3, 5) + "-" + val.substring(5);
			}
			break;
		case "D":
		case "DT":
			const localDate = new Date(val);
			if (localDate.toString().includes("Invalid") || isNullOrUndefined(val)) f = "";
			else {
				f = localDate.toLocaleDateString() + (typeCode === "DT" ? " " + anyWin.formatTime(localDate) : "");
			}
			break;
		case "TD":
			if (!isNullOrUndefined(val)) {
				local = val.toString();
				if (local.includes(":")) {
					local = parseInt(local.split(":")[0]) * 60 + parseInt(local.split(":")[1]);
				}
				if (!isNaN(parseInt(local))) {
					local = parseInt(local);
					f =
						Math.floor(local / 60)
							.toString()
							.padStart(2, "0") +
						":" +
						(local % 60).toString().padStart(2, "0");
				}
			}
			break;
		case "ESIGN":
			f = isNullOrUndefined(val) ? "No Signature" : "Signature on File";
			break;
		case "L":
			f = parseBoolean(val) ? "Yes" : "No";
			break;
		default:
			f = val;
			break;
	}

	return f;
};

anyWin.isNullOrUndefined = function (val: any) {
	return val === null || typeof val === "undefined" || val.toString().trim() === "";
};

export function validateEmail(val: any) {
	if (!new RegExp(".*@.*..*").test(val)) {
		alert(`${val} is not a valid email address`);
		return "";
	}

	return val;
}

anyWin.emptyFormObject = function (form: any) {
	var formObject = new FormObject(cloneObject(form));
	formObject.formMode = "ADD";
	formObject.keyValue = "new";
	formObject.isDirty = false;

	formObject.hasData = false;
	formObject.subFormHasData = false;

	formObject.FormLines = formObject.FormLines.map(function (fl: any) {
		switch (fl.typeCode) {
			case "FK":
				fl.lutValue.value = null;
				fl.lutValue.description = null;
				fl.lutValue.prompt = null;

				fl.lutOldValue.value = null;
				fl.lutOldValue.description = null;
				fl.lutOldValue.prompt = null;
				break;
			case "ESF":
			case "SF":
				var subForm = cloneObject(fl.sfValue[0]);
				subForm.hasData = false;
				fl.sfValue = [];
				fl.sfValue.push(anyWin.emptyFormObject(subForm));
				break;
			case "TESTS":
				fl.testValue.Questions.forEach(function (q: any) {
					q.test_details_remarks = null;
					q.test_details_id = "new";

					q.Answers.forEach(function (a: any) {
						switch (a.question_answer_type) {
							case 5:
							case 4:
							case 3:
							case 2:
							case 1:
							case 0:
								a.value = null;
								break;
							default:
								break;
						}
						a.test_details_answers_id = "new";
					});
				});
				break;
			default:
				fl.value = null;
				fl.oldValue = null;
				break;
		}

		delete fl.defaultValueEvald;
		return new FormLine(fl);
	});

	return formObject;
};

anyWin.cloneObject = function (obj: any) {
	return JSON.parse(JSON.stringify(obj));
};
anyWin.todaysDateTime = function () {
	return new Date();
};
anyWin.todaysDate = function () {
	return new Date(FormatDataByTypeCode(new Date(), "D"));
};

anyWin.getDataValue = function (tableName: any, codeField: any, codeValue: any, returnField: any) {
	if (isNullOrUndefined(codeValue)) return "";

	if (typeof codeValue === "string" && codeValue.toLowerCase().startsWith("new")) return "";

	var res = JSON.parse(anyWin.syncFetch("form/GetDataValue", JSON.stringify({ tableName: tableName, codeField: codeField, codeValue: codeValue, returnField: returnField })));

	return res;
};

export function getDataValueTS(tableName: string, codeField: string, codeValue: string, returnField: string) {
	return anyWin.getDataValue(tableName, codeField, codeValue, returnField);
}

anyWin.Form = {
	getFormLineByCaption: anyWin.getFormLineByCaption,
	ReinitializeDrivingFormline: () => {},
};

anyWin.syncFetch = function (url: any, data: any) {
	var xhr = new XMLHttpRequest();
	xhr.open("POST", url, false);
	xhr.setRequestHeader("Content-Type", "application/json");
	xhr.send(data);
	return xhr.responseText;
};

export function saveFormObject(form: any) {
	return fetch("form/SaveFormObject", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		cache: "no-cache",
		body: JSON.stringify(form),
	});
}

anyWin.getCurrentForm = function () {
	return anyWin.currentForm;
};

anyWin.isChecked = function (colName: any) {
	return anyWin.parseBoolean(anyWin.getCurrentForm().FormLines.find((x: any) => x.columnName === colName).value);
};

anyWin.decodeJs = function (js: any) {
	return typeof js === "string"
		? js
				.replace(/&amp;/gi, "&")
				.replace(/&lt;/gi, "<")
				.replace(/&gt;/gi, ">")
				.replace(/&nbsp;/gi, " ")
				.replace(/&quot;/gi, '"')
		: js;
};

anyWin.evalAfterLoadScript = function () {
	if (typeof anyWin.currentForm !== "undefined" && !isNullOrUndefined(anyWin.currentForm.afterLoad))
		try {
			eval(anyWin.currentForm.afterLoad);
		} catch (ex) {
			console.error(`Error executing afterLoad ${anyWin.currentForm.afterLoad}: ${ex}
		`);
		}
};

anyWin.evalBeforeSaveScript = function () {
	if (typeof anyWin.currentForm !== "undefined" && !isNullOrUndefined(anyWin.currentForm.beforeSave)) {
		anyWin.formValid = true;
		try {
			eval(anyWin.currentForm.beforeSave);
		} catch (ex) {
			console.error(`Error executing beforeSave ${anyWin.currentForm.beforeSave}: ${ex}`);
		}
	}
};

anyWin.evalDisableRules = function () {
	if (typeof anyWin.currentForm !== "undefined")
		anyWin.currentForm.FormLines.forEach((x: any) => {
			if (x.disableRule) {
				try {
					// eslint-disable-next-line
					const disabled = eval(anyWin.decodeJs(x.disableRule));
					x.setDisabled(disabled);
				} catch (ex) {
					console.error(x.disableRule);
					console.error(ex);
				}
			}
		});
};

anyWin.setFormLineValue = function (column: any, value: any, form?: any) {
	if (!form) form = anyWin.currentForm;

	let fl = anyWin.getFormLineByColumnName(column, form);
	if (!fl) return false;

	switch (fl.typeCode) {
		case "FK":
			fl.lutValue.value = value;
			break;
		default:
			fl.value = value;
			break;
	}

	fl.isDirty = true;

	return true;
};

anyWin.formElementExists = function (colName: any) {
	return !!anyWin.getFormLineByColumnName(colName, null);
};

anyWin.OnChange_InvisbleSF_PN = function () {};

anyWin.checkConfidentialDefault = function () {
	return "false";
};

anyWin.dateToWcf = function (input: any) {
	var d = new Date(input);
	if (isNaN(d.getTime())) return null;
	return "/Date(" + d.getTime() + "-0000)/";
};

anyWin.isNullOrEmpty = function (input: any) {
	return isNullOrUndefined(input);
};

export function isNullOrUndefined(val: any) {
	return anyWin.isNullOrUndefined(val);
}

export function FormatDataByTypeCode(val: any, typeCode: string) {
	return anyWin.FormatDataByTypeCode(val, typeCode);
}

export function cloneObject(val: any) {
	return anyWin.cloneObject(val);
}

export function dateToWcf(val: any) {
	return anyWin.dateToWcf(val);
}

export function emptyFormObject(val: any) {
	return anyWin.emptyFormObject(val);
}

export function evalDisableRules() {
	return anyWin.evalDisableRules();
}

export function getFormElement(val: any, form?: any) {
	return anyWin.getFormElement(val, form);
}

export function getFormLineByColumnName(column: any, form?: any) {
	return anyWin.getFormLineByColumnName(column, form);
}

export function getFormLineById(id: any, form?: any) {
	return anyWin.getFormLineById(id, form);
}

export function getQueryParam(param: any) {
	return anyWin.getQueryParam(param);
}

export function parseBoolean(val: any) {
	return anyWin.parseBoolean(val);
}

export function parseIntFromTd(val: any) {
	return anyWin.parseIntFromTd(val);
}

export function setFormLineValue(column: any, value: any, form?: any) {
	return anyWin.setFormLineValue(column, value, form);
}

export function setPageVariables(form: any) {
	anyWin.setPageVariables(form);
}

export function evalAfterLoadScript() {
	anyWin.evalAfterLoadScript();
}

anyWin.hideFormGroup = function (caption: string) {
	const fo = anyWin.getCurrentForm();
	const cap = caption.toLowerCase();
	const group = fo.FormLines.find((x: any) => x.caption && x.caption.toLowerCase() === cap);
	if (group) {
		group.hide();
	}
};

export function evalBeforeSaveScript() {
	anyWin.evalBeforeSaveScript();
}
